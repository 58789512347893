@mixin linearGradient($direction) {
  background: linear-gradient(
    to $direction,
    $cyan 0%,
    $magenta 33%,
    $yellow 66%,
    $black 100%
  );
}

@mixin bg {
  background: $black;
  @media (orientation: landscape) {
    @include linearGradient(right);
  }
  @media (orientation: portrait) {
    @include linearGradient(bottom);
  }
}

@mixin flexCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin transitionAll($timing) {
  transition: all $timing ease-in-out;
}

@mixin fullHW {
  height: 100%;
  width: 100%;
}

@mixin anim($name, $duration) {
  animation: $name;
  animation-duration: $duration;
}