html,
body,
#root {
  @include fullHW;
  margin: 0;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  @include bg;
}
.cmyk {
  @include fullHW;
  margin: 0;
  display: flex;
  @media (orientation: landscape) {
    flex-direction: row;
  }
  @media (orientation: portrait) {
    flex-direction: column;
  }

  @import "letter";
}
@import "repo";
